import React from 'react';
import { forwardRef } from 'react';
import { Box, BoxProps } from '@material-ui/core';
import styled from 'styled-components';

interface Props extends BoxProps {
  gap?: number;
}

/**
 * Utility component with the same API as Box
 *
 * Differences:
 * - `display="flex"` is automatically applied.
 * - Accepts a `gap` property
 */

const FlexBox = forwardRef(
  (
    { display = 'flex', ...props }: Props,
    ref: React.Ref<React.Component<BoxProps, unknown, unknown>> | undefined
  ) => {
    return <BoxWithGaps display={display} ref={ref} {...props} />;
  }
);

FlexBox.displayName = 'FlexBox';

const BoxWithGaps = styled(Box)<{ gap?: number }>`
  gap: ${({ gap }) => (gap ? gap * 8 : 0)}px;
`;

export default FlexBox;
