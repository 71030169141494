import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

import Star from '@icons/star.svg';
import TrustedShopsSvg from '@icons/trusted-shop-badge.svg';
import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';

interface Props {
  rating: number;
}

const Ratings: React.FC<Props> = ({ rating }) => {
  const isTablet = useBreakpointUp('lg');

  return (
    <Wrapper>
      <IconWrapper>
        <TrustedShopsSvg
          fill="white"
          height={isTablet ? '22px' : '16px'}
          width={isTablet ? '22px' : '16px'}
        />
      </IconWrapper>

      <ContentWrapper pr={3.5}>
        <Stars>
          {Array(Math.ceil(rating))
            .fill(1)
            .map((_, index) => (
              <Star height="10px" width="10px" key={index} />
            ))}
        </Stars>

        <RatingText>
          <RatingNumber>{rating.toFixed(1)} </RatingNumber>
          <RatingFrom>/ 5.0</RatingFrom>
        </RatingText>
      </ContentWrapper>
    </Wrapper>
  );
};

const RatingText = styled.div`
  display: flex;
  align-items: center;
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(0.4)}px;

  ${({ theme: { breakpoints } }) => breakpoints.up('lg')} {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing(2)}px;

  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(3.5)}px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: column;
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
    width: 100%;
    gap: 0px;
  }
`;

const RatingNumber = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 172.5%;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const RatingFrom = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 172.5%;
  align-self: center;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.spacing(1)}px;

  color: #273635;
`;

const IconWrapper = styled(Box)`
  border-radius: 100px;

  background-color: ${({ theme }) => theme.palette.secondary.main};

  margin-left: ${({ theme: { spacing } }) => spacing(1.5)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  min-width: 30px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: 46px;
    min-width: 46px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  background-color: #f9fcea;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-color: inherit;
  }
`;

export default Ratings;
