import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  getExperimentOfVariation,
  isVariationActive,
  Variation,
} from 'lib/ablyft';
import { getQueryParams } from 'shared/helpers/urls';

export function useSyncABTestInQueryParam(variation: Variation) {
  const router = useRouter();
  // Keep the AB test identifiers in the url
  useEffect(() => {
    const lookup = getExperimentOfVariation(variation);
    if (!lookup) {
      return;
    }

    const { experiment } = lookup;

    const variations = Object.values(experiment.variations);

    const activeVariant = variations.find((_variation) =>
      isVariationActive(_variation)
    );

    const experimentQueryParams = variations
      .map(({ queryParam }) => queryParam)
      .filter(Boolean);
    const activeQueryParam = activeVariant?.queryParam;

    const query = { ...getQueryParams(), ...router.query };

    let urlChangeHappened = false;

    // Handle query parameters
    experimentQueryParams.forEach((queryParam) => {
      const shouldBeActive =
        activeQueryParam && queryParam === activeQueryParam;
      const shouldNotBeActive = queryParam && queryParam !== activeQueryParam;

      if (shouldBeActive) {
        if (query[queryParam] !== 'true') {
          query[queryParam] = 'true';
          urlChangeHappened = true;
        }
      }

      if (shouldNotBeActive) {
        if (query[queryParam]) {
          delete query[queryParam];
          urlChangeHappened = true;
        }
      }
    });

    if (urlChangeHappened) {
      router.replace({ query }, undefined, { shallow: true });
      return;
    }
  }, [router, variation]);
}
