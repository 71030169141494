import React, { useRef } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps, Hidden } from '@material-ui/core';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import saladImg from '@images/homepage/floating-food/salad.webp';
import EmbeddedFunnel from 'shared/components/EmbeddedFunnel';
import { getRating } from 'shared/services/trustedShops/handleRatings';
import { Ratings } from 'shared/services/trustedShops/model';
import HeroBenefits from 'static_pages/home/components/Hero/HeroBenefits';
import Container from 'system/components/Container';

interface Props extends BoxProps {
  ratings: Ratings;
}

function Hero({ ratings, ...boxProps }: Props) {
  const { t } = useTranslation('static_pages');

  const questionRef = useRef<null | HTMLFormElement>(null);

  return (
    <React.Fragment>
      <Wrapper
        as="section"
        width="100%"
        id="heroSection"
        className="hey15__control"
        {...boxProps}
      >
        <Content>
          <Container size="md">
            <Box
              mx="auto"
              px={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              maxWidth={{ sm: 600, lg: 'none' }}
            >
              <Title variant="h2" mb={2} component="h1">
                <Trans
                  t={t}
                  i18nKey="baseHero.title"
                  components={[
                    <Typography
                      variant="inherit"
                      component="inherit"
                      color="secondary"
                      key="title"
                    />,
                  ]}
                />
              </Title>
            </Box>
          </Container>
          <EmbeddedFunnel
            renderTitle={(title) => (
              <Subtitle component="h2" variant="subtitle2">
                {title}&nbsp;
              </Subtitle>
            )}
            ratings={ratings}
            slides={{
              category: { whiteWrapper: true },
              dateAndLead: { whiteWrapper: true },
              dietaryRestriction: { whiteWrapper: true },
              customerTier: { whiteWrapper: true },
            }}
            highlightColor="secondary"
            onBackground="light"
            ref={questionRef}
          />
        </Content>

        <Hidden implementation="css" smDown>
          <Box
            position="absolute"
            left={{ xs: '48%', md: '-20px', lg: '50px' }}
            bottom={{ xs: '-10px', md: '-100px' }}
            height={{ xs: '120px', md: '200px' }}
            width={{ xs: '50%', md: '220px' }}
          >
            <Image
              alt=""
              priority
              src={saladImg}
              layout="fill"
              objectFit="contain"
            />
          </Box>
        </Hidden>
        <Hidden implementation="css" smDown>
          <Box
            position="absolute"
            left={{ xs: '-10%', md: '-250px' }}
            bottom={{ xs: '-130px', md: '-80px' }}
            height={{ xs: '280px', md: '450px' }}
            width={{ xs: '100%', md: '520px' }}
          >
            <Image
              alt=""
              priority
              src="/artifacts/images/homepage/floating-food/meal.webp"
              layout="fill"
              objectFit="contain"
              sizes="(max-width: 768px) 40vw,
            (max-width: 1200px) 30vw,
            20vw"
            />
          </Box>
        </Hidden>

        <Hidden implementation="css" smDown>
          <Box
            position="absolute"
            top="-30px"
            right="-110px"
            height={{ xs: '330px' }}
            width={{ xs: '400px' }}
            display={{ xs: 'none', md: 'block' }}
          >
            <Image
              alt=""
              src="/artifacts/images/homepage/floating-food/bowl-hands.webp"
              layout="fill"
              priority
              objectFit="contain"
              sizes="(max-width: 768px) 40vw,
            (max-width: 1200px) 30vw,
            20vw"
            />
          </Box>
        </Hidden>
        <Hidden implementation="css" smDown>
          <Box
            position="absolute"
            bottom="80px"
            left={{ md: '80px', lg: '150px' }}
            height={{ xs: '140px' }}
            width={{ xs: '150px' }}
            display={{ xs: 'none', md: 'block' }}
            zIndex="-1"
          >
            <Image
              alt=""
              src="/artifacts/images/homepage/floating-food/barley.webp"
              layout="fill"
              priority
              objectFit="contain"
              sizes="(max-width: 768px) 40vw,
            (max-width: 1200px) 30vw,
            20vw"
            />
          </Box>
        </Hidden>
      </Wrapper>
      <HeroBenefits rating={getRating(ratings)} />
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  background: radial-gradient(
    124.78% 80.11% at 93.04% 116.94%,
    #e2ec97 7.76%,
    #e7f4c3 79.45%
  );
  position: relative;
  overflow: hidden;
  z-index: 2;
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: visible;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-height: 763px;
  }
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 38px;
    line-height: 140%;
  }
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(4, 0, 0)};
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(4, 0, 0)};
  }
`;

const Subtitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  white-space: pre-line;
  line-height: 150%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

export default Hero;
