import React from 'react';
import { Box } from '@heycater/design-system';
import Image from 'next/image';
import styled from 'styled-components';

function TrustedCustomers() {
  return (
    <WrapperBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={'100%'}
      mx="auto"
      flexWrap="wrap"
    >
      <Box
        mx={3}
        my={2}
        ml={0}
        width={{ xs: 35, md: 46 }}
        height={{ xs: 35, md: 46 }}
        position="relative"
      >
        <CustomerLogo
          alt="The logo of Dell"
          src="/artifacts/images/homepage/trust-customer-icons/dell.webp"
          layout="responsive"
          width="46"
          height="46"
        />
      </Box>
      <Box
        mx={3}
        my={2}
        width={{ xs: 67, md: 103 }}
        height={{ xs: 12, md: 25 }}
        position="relative"
      >
        <CustomerLogo
          alt="The logo of Infarm"
          src="/artifacts/images/homepage/trust-customer-icons/infarm.webp"
          layout="responsive"
          width="103"
          height="25"
        />
      </Box>
      <Box
        mx={3}
        my={2}
        width={62}
        height={{ xs: 13, md: 25 }}
        position="relative"
      >
        <CustomerLogo
          alt="The logo of Ebay"
          src="/artifacts/images/homepage/trust-customer-icons/ebay.webp"
          layout="responsive"
          width="62"
          height="25"
        />
      </Box>
      <Box
        mx={3}
        my={2}
        width={{ xs: 44, md: 66 }}
        height={{ xs: 22, md: 32 }}
        position="relative"
      >
        <CustomerLogo
          alt="The logo of Hellofresh"
          src="/artifacts/images/homepage/trust-customer-icons/hellofresh.webp"
          layout="responsive"
          width="66"
          height="32"
        />
      </Box>
      <Box
        mx={3}
        my={2}
        width={{ xs: 47, md: 56 }}
        height={{ xs: 9, md: 20 }}
        position="relative"
      >
        <CustomerLogo
          alt="The logo of Audi"
          src="/artifacts/images/homepage/trust-customer-icons/audi.webp"
          layout="responsive"
          width="56"
          height="20"
        />
      </Box>
    </WrapperBox>
  );
}

const WrapperBox = styled(Box)`
  filter: brightness(1.5);
`;

const CustomerLogo = styled(Image)`
  margin: ${(props) => props.theme.spacing(0, 10)};
`;

export default TrustedCustomers;
