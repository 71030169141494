import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { isVariationActive, Variation } from 'lib/ablyft';

export function useVariationActive(variation: Variation) {
  const router = useRouter();

  const isActive = useMemo(() => isVariationActive(variation), [
    variation,
    router,
  ]);

  return isActive;
}
