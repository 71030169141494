import React from 'react';
import { Box } from '@heycater/design-system';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

import { getContentfulImageUrl } from 'hub/helpers/contentful';
import { withQualificationProvider } from 'qualification/context';
import MainLayout from 'shared/layouts/MainLayout';
import GetItDone from 'static_pages/home/components/GetItDone';
import Hero from 'static_pages/home/components/Hero';
import { HomepageProps } from 'static_pages/home/model';
import { useHomepageTracking } from 'static_pages/home/useHomepageTracking';
import Container from 'system/components/Container';

const Caterings = dynamic(
  () => import('static_pages/home/components/Caterings')
);
const ExpertSection = dynamic(
  () => import('static_pages/home/components/ExpertSection')
);
const FooterLinks = dynamic(
  () => import('static_pages/components/FooterLinks')
);
const OurTeamSection = dynamic(
  () => import('static_pages/home/components/OurTeamSection')
);
const Benefits = dynamic(() => import('static_pages/home/components/Benefits'));
const Testimonials = dynamic(() => import('shared/components/Testimonials'));
const InspirationsSection = dynamic(
  () => import('static_pages/home/components/InspirationSection')
);
const SEOCopy = dynamic(() => import('static_pages/home/components/SEOCopy'));
const CTAWithBenefits = dynamic(
  () => import('static_pages/components/CTAWithBenefits')
);
const Faqs = dynamic(() => import('static_pages/home/components/Faqs'));
const ProfessionalOrPrivateSection = dynamic(
  () => import('static_pages/home/components/ProfessionalOrPrivateSection')
);
const HomepageNewsletter = dynamic(
  () => import('static_pages/home/components/HomepageNewsletter')
);

function Homepage({
  landingPageCards,
  expert,
  testimonials,
  expertButtonLink,
  expertSectionTitle,
  expertButtonLabel,
  faq,
  ratings,
  faQsHeading,
  articles,
}: HomepageProps) {
  const { t } = useTranslation('static_pages');
  useHomepageTracking();
  return (
    <React.Fragment>
      <Head>
        <title>{t('homepage.meta.title')}</title>

        <meta name="description" content={t('homepage.meta.description')} />
      </Head>
      <MainLayout ratings={ratings}>
        <Box mb={{ xs: 4, md: 6, lg: 10 }}>
          <Hero ratings={ratings} />
          <GetItDone mb={{ xs: 7, md: 9 }} />
        </Box>
        {testimonials?.length ? (
          <Box as="section" bgcolor={'#F9F9F7'} mb={9}>
            <Container size="md">
              <Testimonials
                items={testimonials.map((testimonial) => ({
                  imageSrc: getContentfulImageUrl(testimonial.fields.image),
                  body: testimonial.fields.body,
                  company: {
                    name: testimonial.fields.company,
                    image: testimonial.fields.companyLogo
                      ? {
                          src: getContentfulImageUrl(
                            testimonial.fields.companyLogo
                          ),
                          height:
                            testimonial.fields.companyLogo.fields.file.details
                              .image?.height,
                          width:
                            testimonial.fields.companyLogo.fields.file.details
                              .image?.width,
                        }
                      : undefined,
                  },
                  title: testimonial.fields.title,
                  name: testimonial.fields.name,
                }))}
              />
            </Container>
          </Box>
        ) : null}

        <Box mb={{ xs: 7, md: 10 }}>
          <Benefits />
        </Box>
        <Container size="md">
          <OurTeamSection mb={{ xs: 4, sm: 6, md: 14 }} />
        </Container>
        <Container size={1150} mb={{ xs: 5, md: 9 }}>
          <InspirationsSection posts={articles} />
        </Container>
        <Box mb={{ xs: 8, md: 9 }}>
          <CTAWithBenefits imageSrc="/artifacts/images/homepage/homepage-cta-food.webp" />
        </Box>
        <Container size="md" mb={{ xs: 6, sm: 12 }}>
          <ProfessionalOrPrivateSection />
        </Container>
        {faq?.fields?.items?.length ? (
          <Container size="xsm">
            <Faqs headline={faQsHeading} faqs={faq.fields.items} />
          </Container>
        ) : null}

        {expert && (
          <Container size="xsm" mb={{ xs: 6, sm: 13 }}>
            <ExpertSection
              title={expertSectionTitle}
              imageSrc={getContentfulImageUrl(expert.fields.avatar)}
              name={expert.fields.name}
              ctaLabel={expertButtonLabel}
              ctaLink={expertButtonLink}
              description={expert.fields.description || ''}
            />
          </Container>
        )}
        <SEOCopy mb={6} landingPageCards={landingPageCards} />
        <Container size="md" mb={{ xs: 4, sm: 8 }}>
          <HomepageNewsletter />
        </Container>
        <CTAWithBenefits />
        <Caterings />

        <FooterLinks pb={{ xs: 2, sm: 6 }} pt={{ xs: 2, sm: 3 }} />
      </MainLayout>
    </React.Fragment>
  );
}

export default withQualificationProvider(
  { storage: null, initialized: true },
  Homepage
);
