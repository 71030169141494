import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';
import DarkButton from 'system/components/Button/DarkButton';
import Container from 'system/components/Container';

export default function GetItDone(props: BoxProps) {
  const { t } = useTranslation('static_pages');
  const router = useRouter();
  return (
    <Box mx={4} as="section" id="getItDoneSection" {...props}>
      <Container size="md">
        <Wrapper>
          <Box>
            <ImageWrapper>
              <Image
                alt=""
                src="/artifacts/images/get-it-done-bowl.webp"
                width={325}
                height={215}
                objectFit="contain"
              />
            </ImageWrapper>
            <ContentWrapper>
              <CardTitle component="h4" mb={2}>
                {t('homepage.getItDone.section1.title')}
              </CardTitle>
              <CardDescription>
                {t('homepage.getItDone.section1.content')}
              </CardDescription>
            </ContentWrapper>
          </Box>
          <Box>
            <ImageWrapper>
              <Image
                alt=""
                src="/artifacts/images/get-it-done-phone.webp"
                width={325}
                height={215}
                objectFit="contain"
              />
            </ImageWrapper>
            <ContentWrapper>
              <CardTitle component="h4" mb={2}>
                {t('homepage.getItDone.section2.title')}
              </CardTitle>
              <CardDescription>
                {t('homepage.getItDone.section2.content')}
              </CardDescription>
            </ContentWrapper>
          </Box>
          <Box>
            <ImageWrapper>
              <Image
                alt=""
                src="/artifacts/images/get-it-done-computer.webp"
                width={325}
                height={215}
                objectFit="contain"
              />
            </ImageWrapper>
            <ContentWrapper>
              <CardTitle component="h4" mb={2}>
                {t('homepage.getItDone.section3.title')}
              </CardTitle>
              <CardDescription>
                {t('homepage.getItDone.section3.content')}
              </CardDescription>
            </ContentWrapper>
          </Box>
        </Wrapper>
        <Box
          mt={6}
          display="flex"
          flexDirection="column"
          justifyContent={'center'}
          alignItems="center"
          textAlign="center"
        >
          <Link href={buildFunnelUrl({ router })} passHref>
            <DarkButton component="a" rounded size="lg">
              {t('common:labels.requestCaterersNow')}
            </DarkButton>
          </Link>
          <Typography mt={1.5} variant="body">
            {t('common:labels.requestCaterersCTA')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

const CardTitle = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  white-space: pre-line;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;

const CardDescription = styled(Typography)`
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

const ImageWrapper = styled.div`
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`;

const ContentWrapper = styled.div`
  text-align: center;
  max-width: 350px;
  margin: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)}px;

  & > * {
    flex: 1 1;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(8)}px;
  }
`;
