import { Button } from '@heycater/design-system';
import styled, { css } from 'styled-components';

const DarkButton = styled(Button)`
  border: none;
  line-height: 1;
  min-height: 42px;
  display: inline-grid;
  place-content: center;
  white-space: normal;

  ${({ outline }) =>
    outline
      ? css`
          border: 1px solid #3c4340;
          color: #3c4340;
          background-color: transparent;
          :hover {
            background-color: rgba(0, 0, 0, 0.07);
            border-color: inherit;
          }
        `
      : css`
          background-color: #3c4340;
          :hover {
            background-color: #242927;
          }
        `}
`;

export default DarkButton;
