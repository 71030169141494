import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { ABLyft } from 'lib/ablyft';
import { useQualification } from 'qualification/context';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { HOMEPAGE_FUNEL_SECTION } from 'static_pages/home/redesign/components/utils';

const QF_ENTRY = '/start';
const EQF_ENTRY = `#${HOMEPAGE_FUNEL_SECTION}`;

export function useHomepageTracking() {
  const { state, canContinue: isCurrentStepAnswered } = useQualification();
  const router = useRouter();
  const firstInteractionWithEmbeddedFunnelRef = useRef<null | Date>(null);
  const firstEntryToAnyFunnelRef = useRef<null | Date>(null);
  const { currentUserAccount } = useCurrentUser();

  const isLoggedIn = !!currentUserAccount;

  const trackInteractionWithEmbeddedFunnel = useCallback(() => {
    firstInteractionWithEmbeddedFunnelRef.current = new Date();
    ABLyft.trackCustomEvent(
      'interacted-with-embedded-funnel-on-homepage-not-logged-in-every'
    );
    ABLyft.trackCustomEvent(
      'interacted-with-embedded-funnel-on-homepage-not-logged-in-unique'
    );
  }, []);

  const trackEntryToAnyFunnel = useCallback(() => {
    firstEntryToAnyFunnelRef.current = new Date();
    ABLyft.trackCustomEvent(
      'triggered-funnel-entry-point-on-homepage-not-logged-in-every'
    );
    ABLyft.trackCustomEvent(
      'triggered-funnel-entry-point-on-homepage-not-logged-in-unique'
    );
  }, []);

  useEffect(() => {
    // Track when first interaction happens with the embedded funnel
    if (state.status !== 'answering' || isLoggedIn) {
      return;
    }

    if (isCurrentStepAnswered || state.qualification.stepIndex > 0) {
      const triggeredEntryToAnyFunnel = !!firstEntryToAnyFunnelRef.current;
      const interactedWithEmbeddedFunnel = !!firstInteractionWithEmbeddedFunnelRef.current;

      if (!interactedWithEmbeddedFunnel) {
        trackInteractionWithEmbeddedFunnel();
      }

      if (!triggeredEntryToAnyFunnel) {
        trackEntryToAnyFunnel();
      }
    }
  }, [state, isCurrentStepAnswered, isLoggedIn]);

  useEffect(() => {
    function trackNavigationToQF(
      url: string,
      { shallow }: { shallow?: boolean }
    ) {
      if (isLoggedIn) {
        return;
      }

      const isNavigatingToQF =
        url.endsWith(QF_ENTRY) || url.includes(`${QF_ENTRY}?`);
      if (shallow || !isNavigatingToQF) {
        return;
      }

      const alreadyTriggeredEntryToAnyFunnel = !!firstEntryToAnyFunnelRef.current;

      if (alreadyTriggeredEntryToAnyFunnel) {
        return;
      }
      trackEntryToAnyFunnel();
    }
    router.events.on('beforeHistoryChange', trackNavigationToQF);

    return () => router.events.off('beforeHistoryChange', trackNavigationToQF);
  }, [isLoggedIn]);

  const trackEmbeddedFunnelCTAClick = useCallback(
    ({ target }: MouseEvent) => {
      if (isLoggedIn) {
        return;
      }

      const isElement = target instanceof Element;
      const alreadyEnteredAFunnel = !!firstEntryToAnyFunnelRef.current;

      if (alreadyEnteredAFunnel || !isElement) {
        return;
      }

      const isDirectlyLeadingToEQF =
        target instanceof HTMLAnchorElement &&
        target.href.includes(`${EQF_ENTRY}`);

      if (isDirectlyLeadingToEQF) {
        trackEntryToAnyFunnel();
        return;
      }

      const isParentLeadingToEQF = target.matches(`a[href*="${EQF_ENTRY}"] *`);

      if (isParentLeadingToEQF) {
        trackEntryToAnyFunnel();
        return;
      }
    },
    [isLoggedIn]
  );

  useEffect(() => {
    document.addEventListener('click', trackEmbeddedFunnelCTAClick);
    return () =>
      document.removeEventListener('click', trackEmbeddedFunnelCTAClick);
  }, [trackEmbeddedFunnelCTAClick]);
}
